html {
  --bg-color-default: #fff;
  --color-accent: #0066dd;
  --color-text-default: #333;
  --color-text-light: #afafaf;
  --text-size-small: 0.7em;
  background: var(--bg-color-default);
  color: var(--color-text-default);
  font: 18px/1.6 system-ui, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

@media (prefers-color-scheme: dark) {
  html {
    --bg-color-default: #222;
    --color-text-default: #ddd;
  }
}

body {
  color: var(--color-text-light);
  margin: 0;
  -webkit-text-size-adjust: 100%;
}

a {
  color: inherit;
  font-weight: bold;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

main {
  text-align: center;
}

small {
  font-size: inherit;
}

.footer {
  font-size: var(--text-size-small);
  margin: 0 auto;
  max-width: 76ch;
  padding: 1em;
  text-align: center;
}

:root:not([data-loaded]) .footer {
  display: none;
}

.footer p {
  margin: 0;
}

.footer ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer ul li:not(:first-child):before {
  content: '•';
  padding: 0 4px;
}

.license {
  font-size: var(--text-size-small);
  margin: 0 auto;
  max-width: 76ch;
  padding: 1em 1em 0;
  text-align: center;
}

:root:not([data-loaded]) .license {
  display: none;
}

.photo {
  margin: 0 auto;
}

.kittenLink {
  display: block;
}

.kittenImage {
  display: block;
  margin: 0 auto;
  max-height: 100lvh;
  max-width: 100lvw;
}
